import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';
import SimpleModal from '../modal/modal';
import Slider from '../slider';
import LoginForm from '../account/loginForm';
import { getBlockchainType, getParameterByName } from '../../managers/Helpers';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|health|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

const EDropLanding = ({ onTransferDetailsLoaded, transferToken }) => {
  const [transferCode, setTransferCode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [previewData, setPreviewData] = useState(null);
  const [verifiedCode, setVerifiedCode] = useState(null);
  const [emailExists, setEmailExists] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [loginEmailAddress, setLoginEmailAddress] = useState('');
  const [claimState, setClaimState] = useState(null);

  const scrollToContent = () => {
    const content = document.getElementById('startContent');
    if (content) {
      window.scrollTo({
        top: content.offsetTop - 90,
        behavior: 'smooth',
      });
    }
  };

  const setHeroHeight = () => {
    const hero = document.getElementById('storefrontHero');
    if (hero) {
      hero.style.height = window.innerHeight + 'px';
    }
  };

  const clearAllTransferCookies = () => {
    Object.entries(sessionStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('NFT_TRANSFER')) {
        sessionStorage.removeItem(key);
      }
      return true;
    });
  };

  const prePopulateRegData = () => {
    const registerObj = {};
    
    if (getParameterByName('f')) {
      registerObj.firstName = getParameterByName('f');
    }
    if (getParameterByName('l')) {
      registerObj.lastName = getParameterByName('l');
    }
    if (getParameterByName('e')) {
      registerObj.email = getParameterByName('e');
    }

    if (registerObj.firstName || registerObj.lastName || registerObj.email) {
      sessionStorage.setItem('ETHOS_REGISTRATION', JSON.stringify(registerObj));
    }
  };

  const getTransferCodePreview = async (previewCode) => {
    setIsLoading(true);
    let alreadyClaimed = false;
    
    if (localStorage.getItem('NFT_TOKEN_TRANSFERRED_' + previewCode)) {
      alreadyClaimed = true;
    }
    if (!alreadyClaimed) {
      const preview = await AccountManager.getTransferDetails(null, previewCode);
      if (preview && preview.success === false) {
        setPreviewData(null);
        setErrorData(preview);
      } else if (preview) {
        // tell the parent edrop container we're good
        onTransferDetailsLoaded(preview);
        setPreviewData(preview);
        setVerifiedCode(previewCode);
        sessionStorage.setItem('NFT_TRANSFER_' + previewCode, previewCode);
        // check for pre pop details
        prePopulateRegData();
      } else {
        toast.error('Transfer code not found. Please check your code and try again.');
        setPreviewData(null);
        clearAllTransferCookies();
      }
    } else {
      setClaimState('claimed');
      const preview = await AccountManager.getTransferDetails(null, previewCode);
      if (preview && preview.asset) {
        setPreviewData(preview);
        onTransferDetailsLoaded(preview);
      } else {
        setPreviewData(null);
      }
      clearAllTransferCookies();
    }
    setIsLoading(false);
  };

  const createUnverifiedAccount = async () => {
    const payload = {
      email: emailAddress,
      creatorId: process.env.CREATOR_ID,
    };
    const createAccount = await AccountManager.createUnverifiedAccount(payload);
    if (createAccount && createAccount.token) {
      // authenticate the user with the token and redirect them to the collections page
      AccountManager.setLoginState(createAccount.token);
      window.location.href = '/account/collection';
    } else {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const checkEmailAddress = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (e) {
      e.preventDefault();
    }
    if (!emailPattern.test(emailAddress)) {
      toast.error('Please enter a valid email address.');
      setIsLoading(false);
    } else if (!agreeTerms) {
      toast.error('Please agree to the terms and conditions.');
      setIsLoading(false);
    } else {
      setShowModal(true);
      setEmailExists(false);
      setIsLoading(true);
      const checkEmail = await AccountManager.emailSearch(null, emailAddress);
      if (checkEmail && checkEmail.emailExists === true) {
        setEmailExists(true);
        setIsLoading(false);
      } else {
        await createUnverifiedAccount();
      }
    }
  };

  const onEnterKey = (e) => {
    if (e.keyCode === 13) {
      checkEmailAddress();
    }
  };

  const handleEmailChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    let isChecked = false;
    if (e.target.checked) {
      isChecked = true;
    } else {
      isChecked = false;
    }
    setAgreeTerms(isChecked);
  };

  const handleStickySignup = () => {
    const stickySignup = document.getElementById('stickySignup');
    console.log(stickySignup);
    if (!claimState) {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 100) {
          stickySignup.classList.add('-show');
        } else {
          stickySignup.classList.remove('-show');
        }
      });
    }
  };

  const loginSuccess = async () => {
    const successUrl = window.location.href + '#login=success';
    setShowSlider(false);
    if (process.env.E_DROP_ONLY === 'true') {
      window.location.href = '/account/collection';
    } else {
      window.location.href = successUrl;
      window.location.reload();
    }
  };

  const loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  };

  const scrollToTop = (e) => {
    e.preventDefault();
    // const scrollToElement = document.getElementById(element);
    // scrollToElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const openLoginModal = (e) => {
    e.preventDefault();
    setShowModal(false);
    // alert('hello');
    setLoginEmailAddress(emailAddress);
    setShowSlider(true);
  };

  useEffect(() => {
    setTransferCode(transferToken);
    if (transferToken) {
      getTransferCodePreview(transferToken);
    } else {
      setIsLoading(false);
    }
  }, [transferToken]);

  useEffect(() => {
    // on mount
    setHeroHeight();
    window.addEventListener('resize', setHeroHeight);
  }, []);

  useEffect(() => {
    if (previewData) {
      handleStickySignup();
    }
  }, [previewData]);

  return (
    <div id="storefrontHero" className="storefront__hero">
      <Slider isOpen={showSlider} onBeforeClose={() => setShowSlider(false)}>
        <LoginForm
          prepopulateEmail={loginEmailAddress}
          onSuccess={() => loginSuccess()}
          onFail={(error) => loginFailed(error)}
          onRegisterButton={() => setShowSlider(false)} />
      </Slider>
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        {isLoading
          && <Throbber throbberText="Claiming..." />}
        {!isLoading
        && (
        <div>
          {emailExists
            ? (
              <div>
                <div className="account-subheading -slim-padding">
                  An account with this email already exists. Log in now to join!&nbsp;
                </div>
                <div className="form-cta">
                  <a
                    href="#"
                    onClick={(e) => openLoginModal(e)}
                    className="button">
                    Log in
                  </a>
                  {/* <Link
                    to="/login"
                    className="button">
                    Log in
                  </Link> */}
                </div>
              </div>
            )
            : (
              <div>Loader</div>
            )}
        </div>
        )}
      </SimpleModal>
      <div className="storefront__hero--inner">
        {process.env.HERO_ACCENT_BACKGROUND
        && <img className="storefront__hero--accent" src={process.env.HERO_ACCENT_BACKGROUND} alt={process.env.SITE_NAME} />}
        {previewData && previewData.asset
            && (
            <div className="storefront__hero--content">
              <div className="storefront__hero--asset">
                {previewData.assetVariation.mediaFileUrl.includes('.mp4') ? (
                  <video autoPlay="autoplay" playsInline loop muted poster={previewData.assetVariation.thumbnailUrl}>
                    <source src={previewData.assetVariation.mediaFileUrl} type="video/mp4" />
                    <track
                      default
                      kind="captions"
                      srcLang="en"
                      src="" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={previewData.asset.mediaUrl} alt={previewData.assetVariation.variationName || previewData.asset.name} />
                )}
                <div className="storefront__hero--asset__shadow"></div>
              </div>
              <div className="storefront__hero--logo">
                <img src={process.env.LARGE_LOGO} alt={process.env.SITE_NAME} />
              </div>
              {process.env.CUSTOM_EDROP_TITLE
                ? (
                  <div className="storefront__hero--title">
                    {process.env.CUSTOM_EDROP_TITLE}
                  </div>
                )
                : (
                  <div className="storefront__hero--title">
                    {previewData.assetVariation.variationName || previewData.asset.name}
                  </div>
                )}
              {process.env.CUSTOM_EDROP_DESCRIPTION
                ? (
                  <div
                    className="storefront__hero--description">
                    {process.env.CUSTOM_EDROP_DESCRIPTION}
                  </div>
                )
                : (
                  <div
                    className="storefront__hero--description">
                    {previewData.asset.assetDescription}
                  </div>
                )}
              {claimState === 'claimed'
              && (
              <div className="storefront__hero--claim-state">
                <div className="icon">
                  <img src="https://ipfs.ethosnft.com/ethos/v2/ui/icon-warning.svg" alt="Warning" />
                </div>
                <div className="content">
                  <p>Sorry, access through this link as already been claimed. If you have already claimed access, log in to access it.</p>
                </div>
                <div className="storefront__hero--claim-state__overlay"></div>
              </div>
              )}
              {!claimState
              && (
              <div>
                <div className="storefront__hero--email-capture">
                  <div className="email-capture__input">
                    <div className="storefront__hero--email-capture--overlay"></div>
                    <input
                      type="text"
                      placeholder="Enter your email address"
                      value={emailAddress}
                      onChange={(e) => handleEmailChange(e)}
                      onKeyUp={(e) => onEnterKey(e)} />
                  </div>
                  <div className="email-capture__cta">
                    <button
                      type="button"
                      className="email-capture__button"
                      onClick={(e) => checkEmailAddress(e)}>
                      Join
                    </button>
                  </div>
                </div>
                <div className="storefront__hero--accept-terms">
                  <input
                    id="registerAcceptTerms"
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)} />
                  <label htmlFor="registerAcceptTerms">
                    I agree to the
                    {' '}
                    <a href="https://www.ethosnft.com/terms" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
                    .
                  </label>
                </div>
              </div>
              )}
            </div>
            )}
      </div>
      {previewData && previewData.asset && !claimState
      && (
      <div id="stickySignup" className="sticky-signup">
        <div className="sticky-signup__background">
          <div className="sticky-signup__inner">
            <div className="sticky-signup__row">
              <div className="stick-signup__logo">
                <img src={process.env.LARGE_LOGO} alt={process.env.SITE_NAME} />
              </div>
              <div className="sticky-signup__asset">
                {previewData.assetVariation.mediaFileUrl.includes('.mp4') ? (
                  <video autoPlay="autoplay" playsInline loop muted poster={previewData.assetVariation.thumbnailUrl}>
                    <source src={previewData.assetVariation.mediaFileUrl} type="video/mp4" />
                    <track
                    default
                    kind="captions"
                    srcLang="en"
                    src="" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={previewData.asset.mediaUrl} alt={previewData.assetVariation.variationName || previewData.asset.name} />
                )}
                <div>
                  <div className="sticky-signup__title">{previewData.assetVariation.variationName || previewData.asset.name}</div>
                  <div className="sticky-signup__info">
                    <div className="icon">
                      <img src="https://ipfs.ethosnft.com/ethos/v2/ui/icon-check.png" alt="Warning" />
                    </div>
                    Join Now!
                  </div>
                </div>
              </div>
            </div>

            <div className="sticky-capture">
              <a href="#" className="button button-edrop" onClick={(e) => scrollToTop(e)}>Join</a>
            </div>
          </div>
        </div>
      </div>
      )}
      <div className="hero--more-arrow" onClick={() => scrollToContent()}>
        <div className="more-arrow-bounce">
          Learn more
          <img src="https://ipfs.ethosnft.com/ethos/v2/ui/icon-down-arrow.svg" alt="Warning" />
        </div>
      </div>
    </div>
  );
};
export default EDropLanding;
